@charset "UTF-8";

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 14px;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

@media (max-width: 768px) {
  :root { 
    --bs-body-font-size: 14px;
    --bs-nav-link-font-size: 14px;
  }
}

 body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  /* font-size: var(--bs-body-font-size); */
  font-size: 16px;
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html {
  font-size: 14px;
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.0rem !important;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}



ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -1.5em !important;
  color: #fff;
  background-color: red;
  border-radius: 50px;
  padding: 5px 5px 5px 5px;
  font-size: 8px !important;
 
}

a {
  color: #0d6efd;
  text-decoration: none !important;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}



img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}



th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

/* button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
} */
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}

@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}


/* #### -scrollbar ####*/
::-webkit-scrollbar {
  width: 8px;
  background-color: #ffffff;
}
::-webkit-scrollbar-thumb {
  background-color: #d2d2d2;
  border: 5px solid #ffffff;
 
  border-radius: 4px;
}
/* #### END -scrollbar ####*/

/* #### Footer ####*/
footer {background-color: #f8f9fa;}
/* #### END Footer ####*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.flex-container{
  display: flex;
  flex-direction: row;
}
@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
 .flex-container{
  flex-direction: column;
}
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}








.modal .table-responsive{
  max-height: 450px;
}
.modal .table-responsive thead{
  position: fixed;
  /* z-index: 99999999; */
  background: white;
  width: max-content;
}
@media only screen and (max-width: 896px) {
  .modal .table-responsive thead{
    width: 90%;
  }
}


@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .sb-nav-fixed #layoutSidenav #layoutSidenav_content_ {top: 48px !important}
}


.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}



.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select, .dataTable-selector {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  /* background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}


.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  /* color: var(--bs-white) !important; */
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    transition: none;
    transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  transition: none;
  transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}



.fixed-top, .sb-nav-fixed #layoutSidenav #layoutSidenav_nav, .sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.sizeLogo{
  width : 300px;
}

@media only screen and (min-width: 1800px) {
  .sizeLogo{
    width : 500px;
  }
}  

@media (min-width: 1400px) {
 
}
html,
body {
  height: 100%;
}

#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#layoutAuthentication #layoutAuthentication_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutAuthentication #layoutAuthentication_footer {
  min-width: 0;
}

#layoutSidenav {
  display: flex;
}
#layoutSidenav #layoutSidenav_nav {
  flex-basis: 225px;
  flex-shrink: 0;
  transition: transform 0.15s ease-in-out;
  z-index: 1038;
  transform: translateX(-225px);
}
#layoutSidenav #layoutSidenav_content_ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 0;
  flex-grow: 1;
  min-height: calc(100vh - 56px);
  margin-left: -225px;
}

.sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
  transform: translateX(0);
}
.sb-sidenav-toggled #layoutSidenav #layoutSidenav_content_:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 1037;
  opacity: 0.5;
  transition: opacity 0.3s ease-in-out;
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content_ {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content_ {
    margin-left: -225px;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content_:before {
    display: none;
  }
}
.sb-nav-fixed .sb-topnav {
  z-index: 1039;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav {
  width: 226px;
  height: 100vh;
  z-index: 1038;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav {
  padding-top: 56px;
  /* margin-left: -90px; */
  width: 100%;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav .sb-sidenav .sb-sidenav-menu {
  overflow-y: auto;
}
.sb-nav-fixed #layoutSidenav #layoutSidenav_content_ {
  padding-left: 225px;
  top: 56px;
}

#layoutError {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
#layoutError #layoutError_content {
  min-width: 0;
  flex-grow: 1;
}
#layoutError #layoutError_footer {
  min-width: 0;
}

.img-error {
  max-width: 20rem;
}

.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.3rem;
}

.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
}
.sb-topnav .navbar-brand {
  width: 205px;
  margin: 0;
  text-shadow: 0px 1px 2px #000, 0 0 25px #fff, 0 0 10px #FF0000;
  text-align: center;
  font-weight: 700;
}
.sb-topnav.navbar-dark #sidebarToggle {
  color: rgba(255, 255, 255, 0.5);
}
.sb-topnav.navbar-light #sidebarToggle {
  color: #212529;
}
@media (max-width: 576px) {
  .sb-topnav {
    height: 48px;
  }
}
.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu {
  flex-grow: 1;
}
.sb-sidenav .sb-sidenav-menu .nav {
  flex-direction: column;
  flex-wrap: nowrap;
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-heading {
  padding: 1.75rem 1rem 0.75rem;
  font-size: 0.75rem;
  font-weight: bold;
  text-transform: uppercase;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link {
  display: flex;
  align-items: center;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-nav-link-icon {
  font-size: 0.9rem;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link .sb-sidenav-collapse-arrow {
  display: inline-block;
  margin-left: auto;
  transition: transform 0.15s ease;
}
.sb-sidenav .sb-sidenav-menu .nav .nav-link.collapsed .sb-sidenav-collapse-arrow {
  transform: rotate(-90deg);
}
.sb-sidenav .sb-sidenav-menu .nav .sb-sidenav-menu-nested {
  /* margin-left: 1.5rem; */
  width: 90%;
  flex-direction: column;
}
.sb-sidenav .sb-sidenav-footer {
  padding: 0.75rem;
  flex-shrink: 0;
}

.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: rgba(255, 255, 255, 0.25);
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link:hover {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-menu .nav-link.active .sb-nav-link-icon {
  color: #fff;
}
.sb-sidenav-dark .sb-sidenav-footer {
  background-color: #343a40;
}

.sb-sidenav-light {
  background-color: #f8f9fa;
  color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .sb-sidenav-menu-heading {
  color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link {
  color: #212529;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-nav-link-icon {
  color: #adb5bd;
}
.sb-sidenav-light .sb-sidenav-menu .nav-link .sb-sidenav-collapse-arrow {
  color: #adb5bd;
}

.sb-sidenav-light .sb-sidenav-menu .nav-link:hover, 
.sb-sidenav-light .sb-sidenav-menu .nav-link.active, 
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover .sb-sidenav-collapse-arrow,
.sb-sidenav-light .sb-sidenav-menu .nav-link.active .sb-nav-link-icon, 
.sb-sidenav-light .sb-sidenav-menu .nav-link:hover .sb-nav-link-icon {
  color: #0d6efd;
}

.sb-sidenav-light .sb-sidenav-footer {
  background-color: #e9ecef;
}

.dataTable-wrapper .dataTable-container {
  font-size: 0.875rem;
}

.dataTable-wrapper.no-header .dataTable-container {
  border-top: none;
}

.dataTable-wrapper.no-footer .dataTable-container {
  border-bottom: none;
}

.dataTable-top {
  padding: 0 0 1rem;
}

.dataTable-bottom {
  padding: 0;
}

.dataTable-top > nav:first-child,
.dataTable-top > div:first-child,
.dataTable-bottom > nav:first-child,
.dataTable-bottom > div:first-child {
  float: left;
}

.dataTable-top > nav:last-child,
.dataTable-top > div:last-child,
.dataTable-bottom > nav:last-child,
.dataTable-bottom > div:last-child {
  float: right;
}

.dataTable-selector {
  width: auto;
  display: inline-block;
  padding-left: 1.125rem;
  padding-right: 2.125rem;
  margin-right: 0.25rem;
}

.dataTable-info {
  margin: 7px 0;
}

/* PAGER */
.dataTable-pagination a:hover {
  background-color: #e9ecef;
}

.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background-color: #0d6efd;
}

.dataTable-pagination .ellipsis a,
.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
}

.dataTable-pagination .disabled a,
.dataTable-pagination .disabled a:focus,
.dataTable-pagination .disabled a:hover {
  cursor: not-allowed;
  opacity: 0.4;
}

.dataTable-pagination .pager a {
  font-weight: bold;
}

/* TABLE */
.dataTable-table {
  border-collapse: collapse;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  vertical-align: top;
  padding: 0.5rem 0.5rem;
}

.dataTable-table > thead > tr > th {
  vertical-align: bottom;
  text-align: left;
  border-bottom: none;
}

.dataTable-table > tfoot > tr > th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th {
  vertical-align: bottom;
  text-align: left;
}

.dataTable-table th a {
  text-decoration: none;
  color: inherit;
}

.dataTable-sorter {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
  padding-right: 1rem;
}

.dataTable-sorter::before,
.dataTable-sorter::after {
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  right: 4px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  opacity: 0.2;
}

.dataTable-sorter::before {
  bottom: 4px;
}

.dataTable-sorter::after {
  top: 0px;
}

.asc .dataTable-sorter::after,
.desc .dataTable-sorter::before {
  opacity: 0.6;
}

.dataTables-empty {
  text-align: center;
}

.dataTable-top::after,
.dataTable-bottom::after {
  clear: both;
  content: " ";
  display: table;
}

.btn-datatable {
  height: 20px !important;
  width: 20px !important;
  font-size: 0.75rem;
  border-radius: 0.25rem !important;
}

/* Menu */

.menu-table-cell {
  cursor: pointer;
}

.menu-table-cell:hover {
  background-color: rgba(245, 245, 245, 0.69);
}

.disabled-cell {
  opacity: 0.69;
}

/* Menu */

/* Input week */

input[type="week"]::-webkit-calendar-picker-indicator{
  margin-top: 6.5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 6.5px;
  color: rgba(0, 0, 0, 0);
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAJhJREFUOE/Fk7EOgzAMRO8WhNSVP6UbXdul/Go3OsByVVBchZBUslCFl8h28myfYiIzSS8AItmlqVqcBYBCjOQmJ6kYXy9JegDoATQ5sOIvAEaSgwFmx2NjziRbA6zteS2MudPADYkaHOvAFHZXtxESwCVC3vH86X81yABBlykBVP0SwDXFXwDnaXD4K98BXB37EJbpSfL2AR1kWBG78jYxAAAAAElFTkSuQmCC) no-repeat ;
}

input[type="week"]::-webkit-calendar-picker-indicator:hover {
	cursor: pointer;
}



/* Input week */
/*  */
header .navbar {
	border-radius: 0px;
}

#header.top-head {
	/* background: #fff; */
	margin-top: -15px;
}

#header .navbar>.container-fluid {
	position: fixed;
	background: #FFF;
	/* margin-top: 35px; */
	top: 0;
	z-index: 1048;
	opacity: .97;
	box-shadow: 0 15px 19px -19px rgba(0, 0, 0, 0.1);
}

header .navbar-default {
	background-color: transparent;
	border: none;
	margin-bottom: 0px;
	position: relative;
	-webkit-box-shadow: 0 15px 19px -19px rgba(0, 0, 0, 0.1);
	box-shadow: 0 15px 19px -19px rgba(0, 0, 0, 0.1);
}

header .navbar-default .navbar-header {
	z-index: 1030;
	position: relative;
	/* background: #fff; */
}

/*== Logo ==*/

.navbar-brand {
	/* background-image: url('../../../../public/logo-vplife-icon.png'); */
	float: left;
	/* padding: 21px 15px; */
	font-size: 18px;
	line-height: 50px;
	height: 66px;
	/* border-right: 1px solid #e8e8e8; */
	margin-right: 0;
}
.navbar-brand-footer img{
  height: 40px !important;
  margin-top: -15px;
}

.web-sh.navbar-form {
	padding: 16px 15px;
	margin: 0px;
	width: 72%;
	border: none;
}

.left-rs {}

.navbar-brand>img {
	/* width: 9rem; */
	display: block;
} 
.navbar-brand .img1 {
	width: 3rem;
	display: none;
} 
.web-sh.navbar-form input {
	width: 100%;
	background: none;
	border: none;
	box-shadow: none;
	/* background-image: url("../images/search-icon.png"); */
	background-repeat: no-repeat;
	background-position: left;
	padding-left: 30px;
	color: #474747;
	font-weight: 400;
}

.web-sh.navbar-form input::-webkit-input-placeholder {
	/* Chrome */
	color: #474747;
}

.web-sh.navbar-form input:-ms-input-placeholder {
	/* IE 10+ */
	color: #474747;
}

.web-sh.navbar-form input::-moz-placeholder {
	/* Firefox 19+ */
	/* color #474747; */
	opacity: 1;
}

.web-sh.navbar-form input:-moz-placeholder {
	/* Firefox 4 - 18 */
	color: #474747;
	opacity: 1;
}

.iq-search .search-btn span {
	font-size: 0;
}

/*== Search Bar ==*/

.search-box {
	background: #fff;
	display: block;
	margin: 0;
	opacity: 0 !important;
	padding: 15px;
	position: absolute;
	right: 0;
	top: -9999px !important;
	transition: visibility 300ms ease 0s, opacity 300ms ease 0s;
	visibility: hidden;
	width: 327px;
	z-index: 888;
	-webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
}

.iq-search .search-box input {
	background: transparent;
	border-radius: 0;
	border: 1px solid #eee;
	padding-left: 15px;
	box-shadow: none;
	color: #666;
}

.iq-search i {
	cursor: pointer;
	font-size: 18px;
	position: absolute;
	right: 27px;
	top: 24px;
}

.iq-search .search-btn::before {
	content: "\f002";
	font-family: "FontAwesome";
	font-size: 14px;
	font-weight: normal;
	left: 0;
	position: absolute;
	text-indent: 0;
	top: 50%;
	margin-top: -9px;
}

.iq-search.search-open .search-btn::before {
	content: "\f00d ";
}

.sidebar-nav {
	position: absolute;
	top: 66px;
	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;
}

#sidebar {
	margin-right: -100%;
	right: 0;
	width: 100%;
	background: #09294c;
	position: fixed;
	height: 100%;
	overflow-y: auto;
	z-index: 1000;
	top: 0;
	-webkit-transition: all .6s cubic-bezier(.19, 1, .22, 1);
	transition: all .6s cubic-bezier(.19, 1, .22, 1);
}

.top-nav ul {
	margin: 0px;
	padding: 0px;
}

.top-nav ul li {
	/* padding: 10px 20px; */
}

.top-nav ul li a {
	color: #fff;
	/* font-family: ; */
	font-size: 18px;
	font-weight: 400;
}

#sidebar.active {
	right: 100%;
	width: 100%;
	transition: all .5s ease-out 0s;
	-webkit-transition: all .5s ease-out 0s;
	-moz-transition: all .5s ease-out 0s;
	-ms-transition: all .5s ease-out 0s;
	-o-transition: all .5s ease-out 0s;
}

.right-nav {
	float: right;
	padding-right: 10px;

}

.login-sr {
	float: right;
	margin-left: 15px;
}

.login-signup {
	padding: 13px 0px;
}

.login-signup ul {
	margin: 0px;
	padding: 0px;
	list-style: none;
}

.login-signup ul li {
	display: inline-block;
}

.login-signup ul li a {
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	color: #000;
	text-decoration: none;
	font-size: 14px;
	display: block;
	padding: 10px 23px;
}

.login-signup ul li a:hover {
	color: #1e96ff;
}

.login-signup ul li a.custom-b {
	background: #09294c;
	border-radius: 3px;
	font-family: 'Raleway', sans-serif;
	font-weight: 500;
	color: #fff;
	padding: 10px 23px;
}

.login-signup ul li a.custom-b:hover {
	background: #1d99ff;
}

.help-r {
	float: right;
}

.help-r {}

.help-box {
	margin: 10px 0px;
	border-right: 1px solid #dcdcdc;
}

.help-box ul {
	padding: 2px 10px 2px 0;
}

.help-box ul li {
	margin: 0px;
	padding: 0px;
	display: inline-block;
	padding-left: 15px;
}

.help-box ul li a {
	font-family: 'Raleway', sans-serif;
	font-weight: 400;
	color: #000;
	text-decoration: none;
	font-size: 14px;
	line-height: 42px;
}

.help-box ul li a:hover {
	color: #1e96ff;
}

.help-box ul li a span {
	padding-right: 10px;
}

.help-box ul li a .h-i {
	padding-right: 10px;
}

.fixed-menu {
	position: fixed;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: #ffffff;
	transition: 0.5s;
	padding: 0px;
	box-shadow: 0 15px 19px -19px rgba(0, 0, 0, 0.1);
}

.nav-b {
	float: right;
}

.nav-box {
	margin: 10px 0px;
	border-right: 1px solid #dcdcdc;
	border-left: 1px solid #dcdcdc;
	padding: 0px 15px;
}

.nav-box ul {
	padding: 12px 0px;
}

.nav-box ul li {
	display: inline-block;
}

.nav-box ul li a {
	/* font-family: 'Raleway', sans-serif; */
	font-weight: 300;
	color: #000;
	text-decoration: none;
	font-size: 18px;
	padding: 0px 10px;
	font-weight: 400;
}

.nav-box ul li a:hover {
	color:#f5ce6b;
}


#layoutSidenav_content_ {
	/* margin-top: 100px; */
}
/* footer */

.main-footer {
	/* background-image: url('../images/footer_bg.png'); */
	background-color: #8ecae6;
	padding: 20px 0px 0px 0px;
	background-size: cover;
	background-position: center;
	color: #fff !important
}
.footer_{
	display: flex;
	flex-wrap: nowrap;
    /* justify-content: flex-start; */
}

.footer-top {
	border-bottom: 1px solid #000;
}

.footer-top h2 {
	/* color: #000; */
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 26px;
	padding-top: 0.8em;
  /* color: #fff !important */
}

.text_footer {
	/* color: #000; */
  /* color: #fff !important; */
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	font-weight: 300;
	line-height: 26px;
	padding-top: 0.5em;
}

.form-box {
	position: relative;
	margin-right: 15px;
}

.form-box input {
	width: 100%;
	box-sizing: border-box;
	background: #fff;
	color: #646464;
	min-height: 51px;
	border-radius: 2px;
	padding-left: 30px;
	font-size: 1rem;
	font-family: 'Raleway', sans-serif;
	padding-right: 140px;
	border-radius: 50px;
}

.form-box button {
	border: none;
	background: #1d99ff;
	color: #fff;
	padding: 11px 35px 11px;
	border-radius: 2px;
	font-weight: 600;
	font-size: 1rem;
	position: absolute;
	right: 5px;
	top: 4px;
	border-radius: 50px;
}

.form-box button:hover {
	background-color: #007dea;
}

.help-box-f {
	/* color: #000; */
  color: #fff !important;
	/* font-family: 'Courier New', Courier, monospace; */
	font-size: 1rem;
	font-weight: 300;
	padding: 4px 0px;

}

.help-box-f h4 {
	color: #000;
  color: #fff !important;
	/* font-family: 'Raleway', sans-serif; */
	font-size: 1rem;
	font-weight: 300;
	padding: 4px 0px;
}

.help-box-f p {
	/* color: #000; */
  color: #fff !important;
	/* font-family: 'Raleway', sans-serif; */
	font-size: 1rem;
	font-weight: 300;
	margin: 0px;
	padding-top: 3px;
}

.footer-link-box {
	padding-top: 20px;
	padding-bottom: 20px;
	padding: 20px 0px;
}

.left-f-box {}

.left-f-box h2 {
	color: #000;
	/* font-family: 'Raleway', sans-serif; */
	font-size: 1em;
	font-weight: 700;
	padding: 0 0 0 10px;
	text-transform: uppercase;
	border-left: solid #007dea 4px;
	/* margin-bottom: 20px; */
	line-height: normal;
  margin-left: 10px;

}

.left-f-box ul {
	margin: 0px;
	padding: 0px;
}

.left-f-box ul li {
  text-align: justify;
	padding-bottom: 10px;
}

.left-f-box ul li a {
	color: #000;
	font-size:16px;
	font-weight: 400;
}

.left-f-box ul li a:hover {
	color: #12345b;
}

.right-f-box {}

.right-f-box h2 {
	color: #000;
	font-family: 'Raleway', sans-serif;
	font-size: 1rem;
	font-weight: 700;
	margin-bottom: 20px;
	text-transform: uppercase;
	padding: 0 0 0 10px;
	border-left: solid #007dea 4px;
}

.right-f-box ul {
	margin: 0px;
	padding: 0px;
}

.right-f-box ul li {
	padding-bottom: 10px;
}

.right-f-box ul li a {
	color: #000;
	font-family: 'Raleway', sans-serif;
	font-size: 1rem;
	font-weight: 400;
}

.right-f-box ul li a:hover {
	color: #fff;
}

/*-- Copyright --*/


.copyright p {

	font-size: 1em;
	color: #000;
	margin: 0;
}

.copyright p img {
	margin-right: 10px;
}

.copyright p a {
	color: #000;
}

.copyright p a:hover {
	text-decoration: underline;
}

.copyright .socials {
	float: right;
	padding: 15px 0px;
}

.copyright .socials {
	margin: 0px;
}

.copyright .socials li {
	margin: 0px;
}

.copyright .socials li a {
	color: #09294c;
	font-size: 18px;
}

.right-flag {
	display: inline-block;
	float: right;
	padding: 8px 0px;
	margin-right: 25px;
}



.right-flag li a {
	color: #626262;
}
/*  */
.text-err{
  color:red;
  font-size: 0.8rem;
  font-style: italic;
  display: flex;
}
.text-succ{
  color:green;
  font-size: 0.8rem;
  font-style: italic;
  display: flex;
}

.two-line-limit {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
}
