#smiley-component * {
  border: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#smiley-component {
  --hue: 0; /* Red hue */
  --bg: hsl(var(--hue), 90%, 90%); /* Light red for background */
  --fg: hsl(var(--hue), 90%, 10%); /* Dark red for foreground */
  --trans-dur: 0.3s;
  font-size: calc(16px + (20 - 16) * (100vw - 320px) / (1280 - 320));
}

#smiley-component body {
  background-color: var(--bg);
  color: var(--fg);
  font: 1em/1.5 sans-serif;
  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur), color var(--trans-dur);
}

#smiley-component .smiley {
  width: 8em;
  height: 8em;
}

#smiley-component .smiley__eye1,
#smiley-component .smiley__eye2,
#smiley-component .smiley__mouth1,
#smiley-component .smiley__mouth2 {
  animation: eye1 3s ease-in-out infinite;
}

#smiley-component .smiley__eye1,
#smiley-component .smiley__eye2 {
  transform-origin: 64px 64px;
}

#smiley-component .smiley__eye2 {
  animation-name: eye2;
}

#smiley-component .smiley__mouth1 {
  animation-name: mouth1;
}

#smiley-component .smiley__mouth2 {
  animation-name: mouth2;
  visibility: hidden;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  #smiley-component {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

/* Animations */
@keyframes eye1 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50%,
  60% {
    animation-timing-function: cubic-bezier(0.17, 0, 0.58, 1);
    transform: rotate(-40deg) translate(0, -56px) scale(1);
  }
  to {
    transform: rotate(225deg) translate(0, -56px) scale(0.35);
  }
}

@keyframes eye2 {
  from {
    transform: rotate(-260deg) translate(0, -56px);
  }
  50% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  52.5% {
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1, 0);
  }
  55%,
  70% {
    animation-timing-function: cubic-bezier(0, 0, 0.28, 1);
    transform: rotate(40deg) translate(0, -56px) rotate(-40deg) scale(1);
  }
  to {
    transform: rotate(150deg) translate(0, -56px) scale(0.4);
  }
}

@keyframes eyeBlink {
  from,
  25%,
  75%,
  to {
    transform: scaleY(1);
  }
  50% {
    transform: scaleY(0);
  }
}

@keyframes mouth1 {
  from {
    animation-timing-function: ease-in;
    stroke-dasharray: 0 351.86;
    stroke-dashoffset: 0;
  }
  25% {
    animation-timing-function: ease-out;
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: 0;
  }
  50% {
    animation-timing-function: steps(1, start);
    stroke-dasharray: 175.93 351.86;
    stroke-dashoffset: -175.93;
    visibility: visible;
  }
  75%,
  to {
    visibility: hidden;
  }
}

@keyframes mouth2 {
  from {
    animation-timing-function: steps(1, end);
    visibility: hidden;
  }
  50% {
    animation-timing-function: ease-in-out;
    visibility: visible;
    stroke-dashoffset: 0;
  }
  to {
    stroke-dashoffset: -351.86;
  }
}
