.shop-services.section {
	/* padding: 80px 0 0px 0; */
	background: #fff;
}
.shop-services.home{
	/* padding:60px 0; */
	background:#F6F7FB;
	/* height: 50px; */
	
 
}
.shop-services .single-service {
	position: relative;
	/* padding-left: 65px; */
	display: flex;
    align-items: center;
}
.shop-services .single-service i {
	height: 50px;
	width: 50px;
	line-height: 50px;
	text-align: center;
	color: #1769b4;
	background: transparent;
	border-radius: 100%;
	display: block;
	font-size: 32px;
	position: absolute;
	left: 0;
	top: 0;
}
.shop-services .single-service h4 {
	font-size: 14px;
	font-weight: 700;
	text-transform: uppercase;
	line-height: 22px;
	color: var(--menu-btn);
}
.shop-services .single-service p {
	color: #898989;
	line-height: 28px;
	font-size:14px;
	margin: 0;
}
.button_banner .btn {
	color: #fff;
	background: #1769b4;
	padding: 10px;
	line-height: initial;
	border: none;
	height: 30px;
	z-index: 0;
	border-radius: 50px;
}
.button_banner .btn:hover{
	background:#ffc107;
	color:#fff;
}
.text-contact{
	color: #5c0000;
    margin-top: 10px;
    font-size: 1.2rem;
    line-height: 22px;
	font-weight: 500;
}

@media (max-width: 769px){

	.row-homepage{
width: 100%;
	}
}