.cown-down {
	position: relative;
	overflow:hidden;
}
.cown-down .padding-right{
	padding-right:0;
}
.cown-down .padding-left{
	padding-left:0;
}
.cown-down .image  {
	/* height:500px; */
}
.cown-down .image img {
	height:100%;
	object-fit: contain;
	aspect-ratio: 4/3;
}
.cown-down .content {
	text-align: center;
	background: #FDFBEF;
	height: 100%;
	position:relative;
}
.cown-down .content .heading-block{
	position:absolute;
	left:0;
	top:50%;
	transform:translateY(-50%);
	padding: 75px;
	line-height: 2rem;
}
.cown-down .content .small-title {
	font-size: 1.5rem;
	color: var(--menu-btn);
	display: block;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-weight: 650;
}
.cown-down .content .title {
	font-weight: 600;
	text-transform: capitalize;
}
.cown-down .content .price {
	margin-top: 35px;
	font-size: 35px;
	font-weight: 700;
	color: #dc3545;
}
.cown-down .content .price s{
	margin-top: 35px;
    font-size: 20px;
    font-weight: 500;
    color: #666;
    font-style: italic;
}
.cown-down .content .cdown {
	float: none;
	text-align:center;
	margin-top:40px;
	width: 80px;
	display:inline-block;
	
}
.cown-down .content .cdown {
	text-align: center;
}
.cown-down .content .cdown{
	display:inline-block;
}
.cown-down .content .cdown span {
	color: #1769b4;
	font-size: 22px;
	font-weight:400;
	text-transform:uppercase;
}
.cown-down .content .cdown p{
	font-size:13px;
	color:#666;
	text-transform:uppercase;
}
@media (max-width:380px) {
	.cown-down .content .heading-block{
		position:relative !important;;
		left:0;
		top:0 !important;
		transform:translateY(0%) !important;
		padding: 75px;
		line-height: 2rem;
	}
	
}